import React, { useState } from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";
import moment from "moment";
export default function Portfolio() {
  const [packages, changePackages] = useState(false);
  const [industry, changeIndustry] = useState(false);
  const [recomended, changeRecomendation] = useState(false);

  const {
    portfolio: { edges },
  } = useStaticQuery(graphql`
    query Showcase {
      portfolio: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/portfolio/" } }
        sort: { fields: [frontmatter___portfolio_id], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              id: portfolio_id
              date: portfolio_date
              name: portfolio_name
              key: portfolio_package_key
              website: portfolio_website_link
              image: portfolio_image {
                publicURL
              }
              icon: portfolio_icon {
                publicURL
              }
              package_icon: portfolio_package_icon {
                publicURL
              }
            }
          }
        }
      }
    }
  `);
  const modalPackage = () => {
    changePackages((preState) => !preState);
    changeIndustry(false);
    changeRecomendation(false);
  };
  // const modalIndustry = () => {
  //   changeIndustry((preState) => !preState);
  //   changePackages(false);
  //   changeRecomendation(false);
  // };
  // const modalRecomended = () => {
  //   changeRecomendation((preState) => !preState);
  //   changeIndustry(false);
  //   changePackages(false);
  // };

  const [plan, setplan] = useState({
    essential: true,
    professional: true,
    commercial: true,
  });
  const [keyword, setKeyword] = useState("");
  const filteredProjects = edges.filter((edge) => {
    let { key, name } = edge.node.frontmatter;
    if (plan[key]) {
      if (name.toLowerCase().indexOf(keyword.toLowerCase()) != -1) {
        return edge;
      }
    }
  });
  return (
    <Layout>
      <SEO
        seo={{
          title: "Portfolio Showcase",
          description:
            "Here are some of our favourite sites that we've built and shared across our social media as well as here on our website.",
          previewURL: "https://merlinpanel.com/images/showcase/preview.png",
        }}
      />
      <div className="mt-20">
        <div className="max-w-6xl mx-auto py-12 px-5 md:px-8">
          <div className="md:p-4 mb-6 ">
            <h2 className="text-center font-bold text-3xl md:text-5xl">
              Portfolio Showcase
            </h2>
            <p className="mx-auto pt-5 text-textcolor text-lg text-center w-full md:w-8/12">
              Here are some of our favourite sites that we've built and shared
              across our social media as well as here on our website.
            </p>
          </div>
          <div className="flex justify-between flex-wrap mb-4">
            <div className="w-full lg:w-9/12">
              <img
                className="absolute search-icon"
                src={require("../media/search.svg")}
                alt="search-icon"
              ></img>
              <input
                className="border outline-none border-borders rounded-md md:mr-6 pl-12 md:pl-16 p-3 w-full  mb-4 md:mb-0"
                placeholder="Search"
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
              />
            </div>
            {/* <button className="border focus:outline-none text-theme border-borders rounded-md p-2 w-1/3 md:w-1/6 py-3">
              Search
            </button> */}
            <div className="w-full mt-2 lg:mt-0 lg:w-1/6 text-center text-theme relative">
              <div
                onClick={() => modalPackage()}
                className="cursor-pointer relative flex justify-between p-3 border border-borders rounded-md"
              >
                <svg width="15" viewBox="0 0 318.21 317.69">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path
                        d="M316.22,183.82A159.19,159.19,0,0,1,160.7,317.69h-1.54C72.25,317.69-.25,245.2,0,158.12a158.81,158.81,0,0,1,8.72-51.64,25.83,25.83,0,0,0-.42,4.61,25.36,25.36,0,0,0,48.21,11,108.44,108.44,0,0,0-6.29,36.19c-.08,28.61,11.33,55.88,32.16,76.76s48.12,32.44,76.78,32.44h1a108.91,108.91,0,0,0,106.32-91v.18a25.37,25.37,0,0,0,49.7,7.15Z"
                        fill="#634394"
                      ></path>
                      <path
                        d="M318.21,158.59a159.48,159.48,0,0,1-1,17.73c-.27,2.53-.6,5-1,7.5a25.37,25.37,0,0,1-49.7-7.15v-.18q.54-3.19.87-6.43a109.16,109.16,0,0,0,.6-11.47c0-1.91-.05-3.83-.15-5.71l0-1.23v-44.5a7.39,7.39,0,0,0-10.67-6.61c-8.9,4.42-21.42,9.83-29.51,9.83a58.94,58.94,0,0,1-38.87-14.64L148.52,60.41a25.15,25.15,0,0,0-24.75-4.85A109.06,109.06,0,0,0,58.09,118c-.57,1.35-1.09,2.73-1.56,4.1,0,0,0,0,0,0a25.36,25.36,0,0,1-48.21-11,25.83,25.83,0,0,1,.42-4.61c1-2.86,2.06-5.67,3.22-8.45A159.27,159.27,0,0,1,145.24.08c.65,0,1.28-.08,1.94-.08a23.06,23.06,0,0,1,15.15,5.72L221.87,58a8.71,8.71,0,0,0,11.48,0L295,13.93a17.94,17.94,0,0,1,11.85-4.47A11.15,11.15,0,0,1,318,20.61v129.8Q318.21,154.48,318.21,158.59Z"
                        fill="#634394"
                      ></path>
                    </g>
                  </g>
                </svg>
                <p className="pr-1">Package</p>
                <p>▾</p>
              </div>

              {packages && (
                <div className="absolute w-full lg:w-40 pt-2 z-10">
                  <ul className="bg-white p-3 text-sm text-textcolor rounded-md w-full features-dropdown ">
                    {["Essential", "Professional", "Commercial"].map((item) => {
                      return (
                        <li className="mb-2 flex justify-between items-center">
                          <span>{item}</span>
                          <div className="w-1/6">
                            <div
                              className={`rounded-md my-auto w-4 h-4 ${
                                plan[item.toLowerCase()]
                                  ? "bg-theme"
                                  : "bg-gray-400"
                              } cursor-pointer`}
                              onClick={() => {
                                setplan({
                                  ...plan,
                                  [item.toLowerCase()]: !plan[
                                    item.toLowerCase()
                                  ],
                                });
                              }}
                            >
                              {plan[item.toLowerCase()] && (
                                <p
                                  style={{ fontSize: "10.5px" }}
                                  className=" text-white text-center"
                                >
                                  ✓
                                </p>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {/* <div className="block md:flex justify-between">
            <div className="flex justify-start md:w-4/12 mb-5">
              
               <div className=" mr-6 w-6/12 text-center text-theme ">
                <div
                  onClick={() => modalIndustry()}
                  className="cursor-pointer relative  flex justify-between p-3 border border-borders rounded-md"
                >
                  <img src={require("../media/industry-alt.svg")}></img>
                  <p className="pr-1">Industry</p>
                  <p>▾</p>
                </div>

                {industry && (
                  <div className="absolute  pt-2 z-10">
                    <ul className="bg-white p-3 text-sm text-textcolor rounded-md w-40 package-dropdown ">
                      {["Essential", "Professional", "Commercial"].map(
                        (item) => {
                          return (
                            <li className="mb-2 flex justify-between items-center">
                              <span>{item}</span>
                              <div className="w-1/6">
                                <div className="rounded-md my-auto w-4 h-4 bg-theme cursor-pointer">
                                  <p
                                    style={{ fontSize: "10.5px" }}
                                    className=" text-white text-center"
                                  >
                                    ✓
                                  </p>
                                </div>
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                )}
              </div>  */}
          {/* </div>
            <div className="ml-1 md:w-2/12">
              <p className="uppercase text-xs tracking-tight text-gray-500">
                sort
              </p>
              <div
                onClick={() => modalRecomended()}
                className="cursor-pointer relative flex justify-between text-center text-theme px-3"
              >
                <p className="pr-1">Recomended</p>
                <p>▾</p>
              </div>

              {recomended && (
                <div className="absolute  pt-2 z-10">
                  <ul className="bg-white p-3 text-sm text-textcolor rounded-md w-40 package-dropdown ">
                    {["Essential", "Professional", "Commercial"].map((item) => {
                      return (
                        <li className="mb-2 flex justify-between items-center">
                          <span>{item}</span>
                          <div className="w-1/6">
                            <div className="rounded-md my-auto w-4 h-4 bg-theme cursor-pointer">
                              <p
                                style={{ fontSize: "10.5px" }}
                                className=" text-white text-center"
                              >
                                ✓
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div> */}
          {filteredProjects.length > 0 ? (
            <div className="flex justify-between flex-wrap mt-16">
              {filteredProjects.map((edge, index) => {
                const {
                  name,
                  date,
                  image,
                  package_icon,
                  website,
                } = edge.node.frontmatter;
                return (
                  <div
                    key={index}
                    className="w-full cursor-pointer sm:w-6/12 md:w-4/12 mb-12 sm:pr-6"
                  >
                    <a rel="noreferrer noopener" href={website} target="_blank">
                      <div className="showcase-card rounded-t-lg rounded-b-lg overflow-hidden">
                        <div>
                          <img alt={`${name}-preview`} src={image.publicURL} />
                        </div>
                        <div className="flex justify-between p-4 mt-2">
                          <div>
                            <h4 className=" text-2xl font-bold">{name}</h4>
                            <p className="text-sm opacity-75 text-textcolor mt-4">
                              {moment(date, "DD MM YYYY").format(
                                "Do MMMM YYYY"
                              )}
                            </p>
                          </div>
                          <img
                            alt={`${name}-icon`}
                            src={package_icon.publicURL}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          ) : (
            <h3 className="w-full text-center mt-4 text-2xl font-bold">
              There is no showcase to show
            </h3>
          )}

          <div className="flex flex-col items-center text-center mt-8">
            <p className="text-center text-textcolor text-lg w-full md:w-3/5">
              If you're a Merlin customer and you'd like us to showcase your
              website and business then please get in touch by contacting us
              here
            </p>
            <Link to="/contact">
              <button className="py-3 px-10 outline-none mx-auto tetx-sm bg-theme text-white rounded-md mt-8 btn-purple-on-white">
                Contact us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
